
.borderfilterdiv{
    border: 2px solid lightblue;
    border-radius: 20px;
    width: 230px;
    height: auto;
    background-color: aliceblue;
}

.datetimediv{
    /*border: 1px solid lightblue;*/
    border-radius: 15px;
    width: 230px;
}
.noticediv{
    /*border: 1px solid lightblue;*/
    border-radius: 15px;
    width: 230px;
}

select {width: 200px; padding: .8em .5em; border: 1px solid #999;font-family: inherit; height: 40px; background-color: antiquewhite; no-repeat: 95% 50%; border-radius: 0px; -webkit-appearance: none; -moz-appearance: none;appearance: none;}

.filterinputdatelabel{
    color: cadetblue;
}

.divcheckbox {text-align: left;}